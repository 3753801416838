import * as React from "react";
import Layout from "../layouts/layout";

import bottle from "../images/hydrocolagina/HydroGraviola.png";
import bottle3 from "../images/hydrocolagina/HydroGraviola3.png";
import result1 from "../images/hydrocolagina/result1.jpg";
import result2 from "../images/hydrocolagina/result2.jpg";
import result3 from "../images/hydrocolagina/result3.jpg";
import result4 from "../images/hydrocolagina/result4.jpg";
import sara from "../images/diabetamina/especialista_sara.png";
import testimonialOne from "../images/hydrocolagina/testimonial_1.jpg";
import testimonialTwo from "../images/hydrocolagina/testimonial_2.jpg";
import Modal from "../components/Modal";
import Form from "../components/hydrocolagina/DynamicForm";
import CTASection from "../components/hydrocolagina/CTASection";

// markup
const Campaign = () => {
	const modalRef = React.useRef();

	const quantity_available = Math.floor(Math.random() * (20 - 3) + 3),
		team_id = 2;

	return (
		<div>
			<Layout title="HydroColagina XXI Guanábana | Mejora el cabello, la piel y las uñas">
				<div className="px-2 pt-32 lg:pt-16 lg:px-0">
					<div
						className="lg:bg-main-hydrocolagina lg:h-fit"
						style={{
							"background-repeat": "repeat, no-repeat",
							"background-size": "auto, cover",
						}}
					>
						<div className="flex flex-col items-center justify-center mt-6 sm:mt-0 lg:text-white px-4 lg:px-72 max-w-6xl">
							<h2 className="text-5xl lg:text-7xl font-semibold pb-4 lg:pb-16 lg:pt-4 text-center">
								HydroColagina XXI + Guanábana
							</h2>
							<h1 className="text-3xl lg:text-5xl font-semibold uppercase">
								Mejora
							</h1>
							<h3 className="text-xl lg:text-2xl">
								Tu salud de adentro hacia afuera
							</h3>
							<h4 className="text-lg lg:text-xl text-center font-semibold lg:mb-4 w-96">
								Potenciado con guanábana, rápida absorción. Mejora las uñas, la
								calidad de la piel, el cabello y los dientes. Elimina arrugas y
								añade firmeza.
							</h4>
						</div>

						<div className="md:max-w-6xl px-4 md:px-40">
							<div className="grid grid-cols-1 lg:grid-cols-3 justify-center">
								<img
									src={bottle}
									className="h-48 w-auto lg:w-72 lg:h-80 lg:mt-12 mx-auto"
									alt="Frasco Hydrocolagina"
								/>

								<div className="lg:col-span-2 lg:mb-12">
									<Form
										product="HydroColagina XXI + Guanabana"
										team_id={team_id}
										onStored={() => modalRef.current.successForm()}
										type="standalone"
										quantity_available={quantity_available}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className="mt-14 mb-2 lg:mb-20 bg-white-abstract">
					<div className="grid grid-cols-2 lg:grid-cols-3 items-center">
						<div className="col-span-2 max-w-4xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16 text-center">
							<h2 className="text-pink-900 uppercase font-bold text-4xl lg:text-6xl mb-1">
								Consulta gratuita
							</h2>
							<h4 className="text-red-600 uppercase font-semibold text-2xl lg:text-4xl">
								Antes de la compra y sin compromisos
							</h4>
							<p className="font-light text-xl lg:text-3xl pt-4">
								Diariamente nuestros especialistas ayudan a más de 200
								consumidores de HydroColagina XXI a guiarlos en el proceso total
								de su tratamiento. Aumentamos la cantidad de especialistas
								calificados y certificados para atender cualquier tipo de
								consulta antes de la compra.
							</p>
							<button
								onClick={() => modalRef.current.openModal()}
								className="bg-yellow-600 hover:bg-yellow-400 py-2 px-3 rounded shadow mt-4 text-lg mb-4 lg:text-2xl font-semibold"
							>
								Obtener el descuento exclusivo para Puerto Rico
							</button>
						</div>
						<img
							src={sara}
							className="w-2/3 hidden lg:block"
							alt="Especialista Sara"
						/>
					</div>
				</section>

				<section className="lg:mt-14 mb-20 py-4 lg:py-12">
					<div className="items-center max-w-8xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16">
						<h2 className="text-orange-500 uppercase text-center font-bold text-4xl mb-4">
							Resultados que impactan
						</h2>
						<div className="flex flex-wrap justify-center gap-4 pt-4">
							<div className="w-72">
								<img src={result1} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Piel más sana, elástica e hidratada,{" "}
									<span className="font-semibold">disminuye las arrugas</span> y
									manchas
								</p>
							</div>
							<div className="w-72">
								<img src={result2} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Cabello con más fuerza, suavidad y forma, reduce puntas
									abiertas,{" "}
									<span className="font-semibold">
										sana cabellos quebradizos
									</span>
								</p>
							</div>
							<div className="w-72">
								<img src={result3} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Mejora el movimiento de las articulaciones, la salud de los
									huesos y músculos,{" "}
									<span className="font-semibold">
										alivia dolores articulares y musculares
									</span>
								</p>
							</div>
							<div className="w-72">
								<img src={result4} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Incrementa la{" "}
									<span className="font-semibold">dureza y firmeza</span> de las
									uñas, encías y dientes, contiene propiedades antioxidantes y{" "}
									<span className="font-semibold">
										mejora el sistema inmune
									</span>
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className="mt-14 mb-20 py-12 bg-white-abstract relative">
					<div className="items-center lg:max-w-6xl px-10 mx-auto sm:px-20 lg:px-32">
						<h2 className="text-orange-500 uppercase text-center font-bold text-3xl lg:text-3xl mb-4">
							Con HydroColagina XXI obtienes los mejores resultados
						</h2>
						<img
							src={bottle3}
							className="h-48 mx-auto lg:mx-0 lg:h-80 mt-12 lg:absolute lg:top-20 lg:left-72"
							alt="Frasco Hydrocolagina"
						/>
						<ul className="list-none text-xl lg:text-2xl lg:pl-72">
							<li className="before:content-['✅'] pt-4">
								Mejora la salud de adentro hacia afuera
							</li>
							<li className="before:content-['✅'] pt-4">
								Mejora la calidad de la piel, el cabello y las uñas
							</li>
							<li className="before:content-['✅'] pt-4">
								Disminuye el dolor en las articulaciones
							</li>
							<li className="before:content-['✅'] pt-4">
								Incrementa las defensas con la ayuda de la vitamina C y la
								guanábana
							</li>
							<li className="before:content-['✅'] pt-4">
								100% natural, sin aditivos artificiales
							</li>
						</ul>
					</div>
				</section>

				<section>
					<div className="bg-[url('./images/hydrocolagina/fondo-guanabana.jpg')] bg-cover bg-center w-full h-fit relative">
						<div className="grid col-span-1 sm:grid-cols-2 justify-center justify-items-center px-4 md:px-48 mx-auto py-28">
							<div className="font-semibold text-center">
								<h2 className="text-3xl text-orange-600 lg:text-4xl uppercase">
									21 tipos de colágeno potenciados con Vitamina C y Guanabana
								</h2>
								<h3 className="text-2xl lg:text-3xl text-red-700">
									Fórmula 100% natural con resultados impactantes
								</h3>
								<div className="flex justify-center gap-x-4 flex-wrap-reverse">
									<ul className="list-none text-2xl pt-12 font-normal">
										<li className="before:content-['➕'] pt-2">
											21 tipos de colágeno hidrolizado que tu cuerpo necesita y
											deja de producir de manera natural después de los 20 años;
											con nuestra fórmula única le darás a tu cuerpo más años de
											vida y salud.
										</li>
										<li className="before:content-['➕'] pt-2">
											Con vitamina C añadida para mejorar la producción y
											cuidado de la piel, los tendones y los vasos sanguíneos,
											además de mantener los huesos y los dientes y ayudar a
											absorber el hierro y{" "}
											<span className="font-semibold">
												mejorar el sistema inmune y tratar el resfriado común
											</span>
											.
										</li>
										<li className="before:content-['➕'] pt-2">
											Potenciado con guanábana o graviola que gracias a sus
											propiedades depurativas y digestivas ayudan contra la
											indigestión, la tensión arterial, la prevención de
											osteoporosis y{" "}
											<span className="font-semibold">
												actúa como un potente antioxidante
											</span>
											.
										</li>
									</ul>
								</div>
							</div>
							<img
								src={bottle3}
								className="hidden sm:block h-60 lg:h-96 mt-12"
								alt="Frasco hydrocolagina"
							/>
						</div>
					</div>
				</section>

				<section className="py-12 max-w-7xl mb-8 mx-auto">
					<h3 className="uppercase text-3xl font-bold text-gray-800 text-center pb-4">
						Comienza a mejorar tu calidad de vida, cuida tu salud de adentro
						hacia afuera - ¡Ordena ahora!
					</h3>
					<div className="shadow-gray-600 shadow-lg rounded-lg border-4 border-dashed border-gray-500 p-6">
						<CTASection
							modalRef={modalRef}
							team_id={team_id}
							quantity_available={quantity_available}
							short={true}
						/>
					</div>
				</section>

				<section className="bg-gray-100 pt-20 pb-24 mt-12">
					<div className="max-w-4xl mx-auto">
						<div className="flex flex-col justify-center gap-8 mx-4 mb-8">
							<div className="grid grid-cols-1 lg:grid-cols-2 bg-white">
								<div className="bg-[url('./images/hydrocolagina/testimonial_1.jpg')] bg-center bg-cover bg-no-repeat hidden lg:block"></div>
								<div className="block lg:hidden">
									<img src={testimonialOne} alt="Sara" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												Mi cabello estaba muy maltratado por las decoloraciones
												que le hice durante varios años, además, sentía que las
												uñas se me quebraban de cualquier manera, vi en la TV el
												anuncio de HydroColagina XXI y comencé a tomarlo el año
												pasado, ahora mi cabello es fuerte y radiante, y por fin
												he logrado que creciera! Estoy muy contenta con los
												resultados y lo recomiendo totalmente.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Sara Rivera, 26 años, San Juan
										</h3>
									</div>
								</div>
							</div>

							<div className="grid grid-cols-1 lg:grid-cols-2 bg-white">
								<div className="bg-[url('./images/hydrocolagina/testimonial_2.jpg')] bg-center bg-cover bg-no-repeat hidden lg:block"></div>
								<div className="block lg:hidden">
									<img src={testimonialTwo} alt="Laura" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												Comencé a tomar HydroColagina XXI porque quería mejorar
												la salud de mi cabello y disminuir un poco las arrugas
												de la cara, ¡pero no esperaba que los resultados fueran
												tan buenos! Mi esposo sufría de dolor en las rodillas y
												comenzó a tomarlo conmigo y ahora el dolor ha disminuido
												casi totalmente, ambos sentimos mejoría en lo que
												estábamos buscando, recomiendo 100% estos productos.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Diana Oliveira y Juan Caunedo, Miami
										</h3>
									</div>
								</div>
							</div>
						</div>

						<h2 className="font-semibold text-2xl lg:text-4xl text-orange-500 uppercase text-center mx-4">
							Comienza a mejorar tu calidad de vida y la salud de tu cuerpo con
							HydroColagina XXI + Guanabana
						</h2>

						<div className="flex justify-center">
							<button
								className="bg-red-600 shadow-md rounded-xl text-white px-4 py-2 mt-3 mx-2 lg:mx-0 font-semibold text-xl"
								onClick={() => modalRef.current.openModal()}
							>
								Hacer el pedido con el descuento y el asesoramiento ahora
							</button>
						</div>
					</div>
				</section>

				<section className="pt-20 pb-12 max-w-6xl mx-auto px-3">
					<p className="text-center text-sm">
						Todos nuestros productos se fabrican en los Estados Unidos en
						instalaciones registradas, cumpliendo siempre con las Buenas
						Prácticas de Fabricación GMP (Good Manufacturing Practice).
						Prestamos atención en todos los pequeños detalles, proporcionando a
						nuestros clientes la mejor calidad con componentes 100% naturales y
						sin aditivos ni conservantes artificiales. Todas nuestras fórmulas
						han sido probadas y verificadas en laboratorios legales y de
						confianza para asegurar que obtendrás los mejores resultados.
						<br />
						<br />
						Aviso: Los productos y la información que se encuentran en este
						sitio no están destinados a reemplazar el tratamiento o consejo
						médico profesional. Estas declaraciones no han sido evaluadas por la
						Food and Drug Administration (FDA). Estos productos no están
						destinados a diagnosticar, tratar, curar o prevenir ninguna
						enfermedad. Los resultados individuales pueden variar.
					</p>
				</section>

				<button
					className="fixed bottom-0 w-full h-12 bg-yellow-600 hover:bg-yellow-500 cursor-pointer transition-colors flex items-center justify-center text-white font-semibold text-lg uppercase"
					onClick={() => modalRef.current.openModal()}
				>
					<p>¡Haz clic aquí para hacer el pedido con el descuento!</p>
				</button>
			</Layout>
			<Modal ref={modalRef}>
				<CTASection
					modalRef={modalRef}
					team_id={team_id}
					quantity_available={quantity_available}
				/>
			</Modal>
		</div>
	);
};

export default Campaign;
